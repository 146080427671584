<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Create Coupon Code</v-app-bar-title>
                        </v-app-bar>
                            <v-card-text>
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <p>The code is required. This is what the customer will enter to apply the coupon.</p>
                            <v-text-field v-model="newCouponCode" label="Coupon code (required)" hint="This will be shared with customers to enter." ref="newCouponCodeInput" outlined dense></v-text-field>
                            <p>The not before and not after dates are optional. If specified, they limit when the coupon code can be used.</p>
                            <v-dialog ref="notBeforeDialog" v-model="notBeforeDialog" :return-value.sync="newNotBefore" persistent width="290px">
                                <template #activator="{ on, attrs }">
                                    <v-text-field v-model="newNotBefore" label="Not before (optional)" readonly hint="Coupon cannot be used earlier than this date" ref="newNotBeforeInput" outlined dense v-bind="attrs" v-on="on">
                                        <template #prepend-inner>
                                            <font-awesome-icon :icon="['fas', 'calendar-alt']" class="mt-1"></font-awesome-icon>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="newNotBefore" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="green darken-2" @click="notBeforeDialog = false">Cancel</v-btn>
                                    <v-btn text color="green darken-2" @click="$refs.notBeforeDialog.save(newNotBefore)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                            <v-dialog ref="notAfterDialog" v-model="notAfterDialog" :return-value.sync="newNotAfter" persistent width="290px">
                                <template #activator="{ on, attrs }">
                                    <v-text-field v-model="newNotAfter" label="Not after (optional)" readonly hint="Coupon cannot be used later than this date" ref="newNotAfterInput" outlined dense v-bind="attrs" v-on="on">
                                        <template #prepend-inner>
                                            <font-awesome-icon :icon="['fas', 'calendar-alt']" class="mt-1"></font-awesome-icon>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="newNotAfter" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="green darken-2" @click="notAfterDialog = false">Cancel</v-btn>
                                    <v-btn text color="green darken-2" @click="$refs.notAfterDialog.save(newNotAfter)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-form>
                            </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        organization: null,
        couponId: null,

        submitFormTimestamp: null,

        // newCouponAlias: null,
        newCouponCode: null,
        newEmail: null,
        newNotBefore: null,
        newNotAfter: null,

        notBeforeDialog: null,
        notAfterDialog: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            couponTypeChoices: (state) => state.couponTypeChoices,
        }),
        isNewItemFormComplete() {
            return typeof this.newCouponCode === 'string' && this.newCouponCode.length > 0;
        },
        newCouponType() {
            if (!Number.isInteger(this.newCouponTypeIndex) || !Array.isArray(this.couponTypeChoices)) {
                return null;
            }
            if (this.newCouponTypeIndex < 0 || this.newCouponTypeIndex >= this.couponTypeChoices.length) {
                return null;
            }
            return this.couponTypeChoices[this.newCouponTypeIndex].value;
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    coupon_id: this.couponId,
                    code: this.newCouponCode,
                };
                if (this.newNotBefore) {
                    item.not_before = this.newNotBefore;
                }
                if (this.newNotAfter) {
                    item.not_after = this.newNotAfter;
                }
                const response = await this.$client.organization(this.$route.params.organizationId).couponCode.create(item);
                if (response?.isCreated && response.id) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.replace({ name: 'organization-edit-coupon-code', params: { organizationId: this.$route.params.organizationId }, query: { id: response.id, t: Date.now() } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                if (err.response?.status === 409) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Coupon code already exists' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        if (this.$route.query.couponId) {
            this.couponId = this.$route.query.couponId;
        }
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('newCouponCodeInput'); }, 1);
        });
    },
};
</script>
